export default defineNuxtRouteMiddleware(async (to, from) => {

  const console = useConsole();
  const requestUrl = useRequestURL();

  console.log('🔌 MDLW:', 'catch-all');

  return navigateTo("/" + (requestUrl.search || ""),{redirectCode: 301});

})
